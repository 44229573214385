<template>
  <div @click="updateSelect">
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>フロー運用</span>
      </div>

      <div class="content">
        <div class="c-left">
          <div  :key="index" v-for="(item, index) in regularList" class="item-box">
            <div class="item-top" :style="{background: item.color}"></div>
            <div class="item-bottom">
              <div>

              </div>

              <p class="item-title">{{item.content}}</p>
              <p class="item-num">{{ item.totalCount }}</p>
            </div>
          </div>
        </div>
        <div class="c-right">
          <div class="category">
            <div v-for="(item, index) in entryFlowList" :key="index" :class="categoryIndex == index ? 'selected' : ''" @click.stop="changeCategoryIndex(index)" class="item">
              <p>{{item.flowName}}</p>
            </div>

          </div>
          <div class="box">
            <div class="box-content">

              <div class="item-box" v-for="(item, index) in entryFlowDetails" :key="index">
                <div :style="{background: item.color}" class="item-top"></div>
                <div class="item-bottom">
                  <div>

                  </div>

                  <p v-show="!item.isNowAdd" class="item-title">{{item.content}}</p>
                  <el-input  v-show="item.isNowAdd" v-model="inputName" class="item-input" @blur="blurInput(item)" :autofocus="true"/>
                  <p class="item-num">{{item.totalCount}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <p class="third-title">学生一覧（選考フォロー中）</p>
      <div class="third-box">
        <div class="content">
          <div class="table-top">
            <p class="name">氏名</p>
            <p class="pseudonym">カナ</p>
            <div class="data-list date-year" :class="dateYearActive ? '' : 'cancel'" @click.stop="changeDateIYearIndex(-1)">
              <p :style="{color: selectDateYear[selectDateYearIndex].kbCode ? 'black' : ''}">{{ selectDateYear[selectDateYearIndex].kbCode ? selectDateYear[selectDateYearIndex].kbCode : '卒年度'}}</p>
              <img src="../../assets/images/index/arrow_down_two.png">

              <div class="list">
                <p class="item" @click.stop.stop="changeDateIYearIndex(index)" :class="index == selectDateYearIndex ? 'selected' : ''" v-for="(item, index) in selectDateYear" :key="index">{{item.kbCode}}</p>
              </div>
            </div>
            <div class="data-list selection-stage" :class="selectionStageActive ? '' : 'cancel'" @click.stop="changeStageIndex(-1)">
<!--              <p>{{ selectionStage[selectionStageIndex].kbCode }}</p>-->
              <p :style="{color: selectionStage[selectionStageIndex].kbCode ? 'black' : ''}">{{ selectionStage[selectionStageIndex].kbCode ? selectionStage[selectionStageIndex].kbCode : '選考階段'}}</p>
              <img src="../../assets/images/index/arrow_down_two.png">

              <div class="list">
                <p class="item" @click.stop="changeStageIndex(index)" :class="index == selectionStageIndex ? 'selected' : ''" v-for="(item, index) in selectionStage" :key="index">{{item.kbCode}}</p>
              </div>
            </div>
          </div>
          <div class="third-list">
            <div class="list-item" v-for="(item, index) in userFlowList" :key="index">
              <p @click="nav(item)" class="name ellipsis">{{item.name}}</p>
              <p class="pseudonym ellipsis">{{item.nameRoma}}</p>
              <p class="date-year ellipsis">{{item.year}}</p>
              <p class="selection-stage ellipsis">{{item.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Message} from "element-ui";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "processOperation",

  data() {
    return {
      categoryIndex: 0,

      colors: ['#8AD8E6', '#89D980', '#F2D15A', '#F7A2A2', '#888888'],

      entryFlowList: [],

      flowNmSelList: [],

      regularList: [],
      entryFlowDetails: [],
      userInfo: {},

      inputName: '',




      selectDateYear: [{}],
      selectDateYearIndex: 0,
      dateYearActive: false,

      selectionStage: [{}],
      selectionStageIndex: 0,
      selectionStageActive: false,

      userFlowList: [],

      userFlowData: [],

      selectId: ''

    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
      this.getEntryFlowList();
      // this.getFlowNmSelList();
      this.getRegularList();

      // console.log(this.userInfo)
    },

    updateSelect() {
      this.dateYearActive = false;
      this.selectionStageActive = false;

    },

    nav(item) {
      // console.log(item)
      this.$router.push('/index/entryLevelDetail?id=' + item.id)
    },

    async getEntryFlowDetails() {
      // let res = await this.$axios({url: `/entryFlow/details`, data: {id: 1}, method: "post"});
      let res = await this.$axios({url: `/entryFlow/details`, data: {id: this.entryFlowList[this.categoryIndex].id}, method: "post"});
      this.selectId = this.entryFlowList[this.categoryIndex].id;
      if(res.code == '000') {
        // res.data.forEach(item => {
        //   item.isNowAdd = false;
        // })
        if(res.data) {
          this.entryFlowDetails = res.data;
          if(res.data.length > 0) {
            this.selectId = res.data[0].id;
          } else {
            this.selectId = '';
          }
          await this.getTableList();
        } else {
          this.entryFlowDetails = [];
        }

      }
    },

    changeSelectId(item) {
      this.selectId = item.id;
      this.getTableList();
    },


    async getTableList() {
      if(this.selectId == '') {
        this.userFlowList = [];
        this.userFlowData = [];
        return;
      }
      let res = await this.$axios({url: `/entryFlow/userFlows`, data: {"compId": this.userInfo.compId, id: this.entryFlowList[this.categoryIndex].id}, method: "post"});
      // console.log(res)

      if(res.data) {
        this.selectDateYear = res.data.yearList?res.data.yearList:[];
        this.selectionStage = res.data.statusList?res.data.statusList:[];
        this.selectDateYear.unshift({kbCode: '', kbName: ''})
        this.selectionStage.unshift({kbCode: '', kbName: ''})
        this.userFlowList = res.data.userFlowList?res.data.userFlowList:[];
        this.userFlowData = res.data.userFlowList?res.data.userFlowList:[];
      } else {
        this.selectDateYear = [];
        this.selectionStage = [];

        this.userFlowList = [];
        this.userFlowData = [];

        this.selectDateYear.unshift({kbCode: '', kbName: ''})
        this.selectionStage.unshift({kbCode: '', kbName: ''})
      }
    },

    async getEntryFlowList() {
      //获取主流程数据列表
      let res = await this.$axios({url: `/entryFlow/list`, data: {
        token:localStorage.getItem("RPA_TOKEN")
        }, method: "post"});
      if(res.code == '000') {
        this.entryFlowList = res.data;
        await this.getEntryFlowDetails();
      }
    },

    //获取固定列表
    async getRegularList() {
      let res = await this.$axios({url: `/entryFlow/sysDetails`, data: {"compId": this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.regularList = res.data;
      }
    },


    async changeCategoryIndex(index) {

      this.categoryIndex = index;
      await this.getEntryFlowDetails();
    },

    changeDateIYearIndex(index) {
      this.dateYearActive = !this.dateYearActive;
      // console.log("sss")
      if(index != -1) {
        this.selectDateYearIndex =  index;
        this.filterData();
      }

    },

    filterData() {
      if(this.selectDateYearIndex > 0 && this.selectionStageIndex >0) {
        this.userFlowList = this.userFlowData.filter(
            (item) => item.year == this.selectDateYear[this.selectDateYearIndex].kbCode &&
                item.content == this.selectionStage[this.selectionStageIndex].kbCode
        )
      } else if (this.selectDateYearIndex > 0) {
        this.userFlowList = this.userFlowData.filter(
            (item) => item.year == this.selectDateYear[this.selectDateYearIndex].kbCode)
      } else if (this.selectionStageIndex > 0) {
        this.userFlowList = this.userFlowData.filter(
            (item) => item.content == this.selectionStage[this.selectionStageIndex].kbCode)
      }
      else{
        this.userFlowList = this.userFlowData;
      }

    },

    changeStageIndex(index) {
      this.selectionStageActive = !this.selectionStageActive;
       console.log("sss",index)
      if(index != -1) {
        this.selectionStageIndex =  index;
        this.filterData();
      }

    }
  }
}
</script>

<style scoped src="../../style/processOperation.css">

</style>
